import React from 'react';
import { Title, LinkRef } from "../styles";
import styled from 'styled-components';

const TalksContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const TalkDate = styled.span`
  color: #333;
  padding-top: 1px;
  width: 70px;
  font-weight: bold;
  font-size: 12px;
`;

const TalkDescription = styled.span`
  flex: 1;
`;

const TalkSection = styled.section`
  margin-bottom: 30px;
`;

function Talks() {
  // Each talk can have a 'link' property which is an object containing:
  // - text: the text to be linked
  // - url: the URL to link to
  const talkItems = [
    {
      date: 'Jan 2025',
      description: 'Talk on ',
      link: {
        text: 'SAM for Improved Generalization on Clinical Raman data',
        url: 'https://spie.org/photonics-west/presentation/Sharpness-aware-minimization-SAM-improves-generalization-performance-of-bacterial-Raman/13307-27'
      },
      descriptionEnd: ' at SPIE Photonics West.'
    },
  ];

  return (
    <TalkSection id="talks">
      <Title>Talks</Title>
      {talkItems.map((item, index) => (
        <TalksContainer key={index}>
          <TalkDate>{item.date}</TalkDate>
          <TalkDescription>
            {item.description}
            <LinkRef href={item.link.url} target="_blank">
              {item.link.text}
            </LinkRef>
            {item.descriptionEnd}
          </TalkDescription>
        </TalksContainer>
      ))}
    </TalkSection>
  );
}

export default Talks;